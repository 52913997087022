











































import { Component, Vue } from 'vue-property-decorator';
import { metaInfo } from '@/utils/seo/metaInfo';
import { buildUrl } from '@/utils/url';

@Component({
  components: {},
  metaInfo: metaInfo({
    title: 'Cookies a podmínky používání | Kakadoo',
    url: buildUrl('/cookies')
  })
})
export default class AboutPage extends Vue {}
